@font-face {
  font-family: 'Frutiger Next';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger Next'), url(./fonts/FrutigerNextLTW1G-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Frutiger Next';
  font-style: normal;
  font-weight: bold;
  src: local('Frutiger Next'), url(./fonts/FrutigerNextLTW1G-Bold.otf) format('opentype');
}
.App {
  height: 95%;
  margin-top: 27px;
  position: absolute;
  left: 0;
  width: 100%;
}

form {
  margin: 0 auto;
  text-align: left;
  max-width: 768px;
}

*{
  font-family: "Frutiger Next";
}

.MuiTextField-root {
  margin-bottom: 20px !important;
}
.error_message{
  margin-top: none;
  margin-bottom: none;
  color: #DC0000;
}
.dropDown {
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  opacity: 75%;
}
.formSubmit {
  margin-top: 10px;
  background-color: #DC0000;
  border: hidden;
}
option {
  font-family: "Frutiger Next" sans-serif;
}
#button-Submit {
  background-color: #DC0000;
  color: #ffffff;
  border: hidden;
  font-weight: bold;
}

#untertitel{
  width: 50%;
  align-content: center;
}

.kalender{
  align-self: center;
}

.extraTextfield{
  width: 100%;
  background-color: black;
  border: 30px solid blue;
  height: 500px;
}

.adminSeiteContent {
    display: flex;
}

.buchungTable {
    width: 100%;
    margin-right: 20px;
}

.btnGroup {
  width: 220px;
  /*
  position: absolute;
  left: 0;
  top: 0;
  */
  
  display: flex;
  flex-direction: column;
  
  margin-left: 20px;
  margin-right: 20px;

}

#btn {
  margin-top: 20px;
}

.detailTable{
  max-width: 700px;
  text-align: center;
  /*
  display: flex;  
  flex-direction: row;
  align-content: center;
  */
  justify-content: center;
  padding-top: 100px;
}

.detailTableHead{
  background-color: #dad6d6;
  padding: 15px;
  border: solid white 5px;
}

.detailPage{
  margin-bottom: 60px;
}

.btnKalender {
  margin-top: 30px;
}

#calendarBtn {
  width: 180px;
  margin: 10px;
}

.loginForm {
  display: left;
  flex-direction: column;
  align-items: left;
}

.captchaButton {
  background-color: #DC0000;
  color: #ffffff;
  width: 190px;
  border: hidden;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  border-radius:5px;
}

.startSeite {
  max-width: 768px;
  text-align: left;
  margin: auto;
  margin-bottom: 32px;
}
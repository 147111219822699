body {
  margin: 0;
  font-family: "Frutiger Next" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Frutiger Next";
}
